/** @interface */
function IAuthRenderer() {
    this.html = (_selector, _value) => { throw new Error('Not implemented'); };
    this.showToast = (_message, _isInfo) => { throw new Error('Not implemented'); };
    this.hideToast = (_message) => { throw new Error('Not implemented'); };
    this.setReqInFlight = (_inFlight) => { throw new Error('Not implemented'); };
    this.onTx = () => { throw new Error('Not implemented'); };
    this.onRx = (_isError) => { throw new Error('Not implemented'); };
    this.onTxWs = () => { throw new Error('Not implemented'); };
    this.onRxWs = () => { throw new Error('Not implemented'); };
    this.displayNonInviteMsg = (_nonInviteErrorMsg) => { throw new Error('Not implemented'); };
    this.displayLoginMsg = (_errMsg) => { throw new Error('Not implemented'); };
    this.refreshApp = () => { throw new Error('Not implemented'); };
    this.onPostLoggedIn = () => { throw new Error('Not implemented'); };
    this.hideShowLockAppFeature = () => { throw new Error('Not implemented'); };
}
module.exports = IAuthRenderer;
