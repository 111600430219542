
const preAppModals = () => {
    return /* html */`
        <div id="modal-logged-out" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1073;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Session Timed Out</h5>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <div id="modal-logged-out-msg" class="modal-body">
                        Your session has timed out, please sign-in again.
                    </div>
                    <div class="modal-footer">
                        <button id="button-logged-out" type="button" class="btn btn-primary" onclick="authManager.logout();return false;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-logged-out-sign-in" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1073;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="modal-logged-out-sign-in-title" class="modal-title">Session Timed Out</h5>
                        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <div id="modal-logged-out-sign-in-msg" class="modal-body">
                        Your session has timed out, please sign-in again. Otherwise, sign-out to end your session.
                    </div>
                    <div
                        style="text-align: center;margin: 10px;display: none;"
                        id="resignin-msg"
                    >
                    </div>
                    <div id="resignin-cognito-section">
                        <div
                            style="display:flex; justify-content: center;margin-bottom: 20px;"
                        >
                            <div
                                class="div-row-v-md"
                                style="width: 280px;"
                            >
                                <form
                                    class="div-row-v-md"
                                >
                                    <div
                                        class="form-floating" style="flex:1"
                                    >
                                        <input
                                            id="resignin-username"
                                            name="username"
                                            type="text"
                                            class="form-control"
                                            autocomplete="off"
                                            required
                                            readonly
                                        ></input>
                                        <label id="label-username" for="resignin-username">Username or Email</label>
                                    </div>
                                    <div
                                        class="form-floating"
                                    >
                                        <input
                                            id="resignin-password"
                                            type="password"
                                            name="password"
                                            class="form-control"
                                            required
                                            autocomplete="off"
                                        ></input>
                                        <label for="resignin-password">Password</label>
                                        <div
                                            style="position: absolute;top: calc(50% - 12px);right: 8%;cursor: pointer;" onclick="bobbobAuth.toggleShowHidePassword('#icon-resignin-show-password', '#resignin-password');">
                                            <i id="icon-resignin-show-password" style="height:24px" class="far fa-eye-slash"></i>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-mm-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-mm-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-mm-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect Metamask"
                                    onclick="bobbobAuth.connectMetamask(event, this, true, false);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>MetaMask</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-ledger-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-ledger-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-ledger-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect Ledger"
                                    onclick="bobbobAuth.checkDownloadLedgerConnect(event, this, true, false);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>Ledger</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-bb-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-bb-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-bb-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect BB Wallet"
                                    onclick="bobbobBbwalletProxy.connectBbwallet(event, this, false, true);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>BB Wallet</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="resignin-bbl-section">
                        <div
                            style="display:flex; flex-direction: column;justify-content: center;margin-bottom: 20px;"
                        >
                            <div id="resignin-bbl-msg" class="modal-body" style="text-align: center;display:none"></div>
                            <div style="display:flex; justify-content: center;margin: 10px">
                                <button
                                    id="resignin-bbl-button"
                                    class='btn btn-primary'
                                    style="width:280px;height:70px;"
                                    title="Connect BB Wallet"
                                    onclick="bobbobBbwalletProxy.connectBbwallet(event, this, true, true);return false;"
                                >
                                    <img style="width: 26px;"></img>
                                    <div>BB Wallet</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="button-logged-out-sign-in-ok" type="button" class="btn btn-primary" onclick="bobbobAuth.cognitoSignin(event, this, true);return false;">Sign-in</button>
                        <button id="button-logged-out-sign-in-cancel" type="button" class="btn btn-primary" onclick="authManager.logout();return false;">Sign-out</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-multi-sign-in" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1073;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Multiple Sign-in Detected</h5>
                    </div>
                    <div class="modal-body">
                        You have signed in from another browser instance, press OK to refresh.
                    </div>
                    <div class="modal-footer">
                        <button id="button-multi-sign-in" type="button" class="btn btn-primary" onclick="preAppReload();window.location.href = '';return false;">OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-different-wallet-sign-in" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" style="z-index: 1073;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Different Wallet Sign-in Detected</h5>
                    </div>
                    <div class="modal-body">
                        You have signed in using a different wallet from another browser instance, press OK to select a wallet to link to app.
                    </div>
                    <div class="modal-footer">
                        <button
                            id="button-different-wallet-sign-in" type="button" class="btn btn-primary"
                            onclick="balanceManager.jumpToWalletSection();return false;"
                            data-bs-dismiss="modal"
                        >OK</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="modal-loading-lib" class="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 id="loading-lib-title" class="modal-title">Gnosis Loader</h5>
                    </div>
                    <div class="modal-body">
                        <div id="lib-loader">
                            <div id="loading-lib-text" style="display:flex;justify-content:center;margin-bottom:10px"></div>
                            <div class="progress progress-normal">
                                <div id="loading-lib-progress" class="progress-bar bg-warning" role="progressbar" style="width: 5%;transition:none !important;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button id="button-loading-lib-retry" disabled type="submit" class="btn btn-primary" onclick="checkDownloadGnosis();return false;">Retry</button>
                        <button id="button-loading-lib-ok" disabled type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    `;
};

module.exports = preAppModals;
